import axios from 'axios';

const BASE_URL = (process.env.NODE_ENV === 'production' ? `${process.env.LIVE_URL}/api/` : `http://localhost:3000/api/`);
const server = axios.create({
    baseURL: BASE_URL
});

function wrapper (Vue) {
    // Check the userstore for existing auth tokens
    if (Vue.user.is_authenticated) {
        server.defaults.headers.common['x-access-token'] = Vue.user.token;
    }

    return {
        // Auth
        login: async function (creds) {
            let res = await server.post('/auth/login', creds);
            Vue.$log.debug(`Successful login: token = ${res.data.token}`);
            // Update user store
            Vue.user.token = res.data.token;
            // Push it to localstorage
            localStorage.setItem('user-jwt', res.data.token);
            // Update the axios default header
            server.defaults.headers.common['x-access-token'] = res.data.token;
            Vue.$log.debug("Successful login");
            this.initial_load();
        },
        logout: async function () {
            // Remove it from localstorage
            Vue.user.token = null;
            Vue.user.empty_store();
            localStorage.removeItem('user-jwt');
            delete server.defaults.headers.common['x-access-token'];
            Vue.$log.debug("Successful logout");
        },


        // Testing functions
        test: async function () {
            Vue.$log.debug("TEST API CALL - GETTING IP ADDRESS");
            let res = await axios.get("http://ipv4.icanhazip.com");
            Vue.$log.debug("TEST COMPLETED - IP IS " + res.data);
            return res.data;
        },
        test_server: async function () {
            Vue.$log.debug("TESTING SERVER API");
            let res = await server.get('test');
            Vue.$log.debug("GOT RESPONSE FROM SERVER" + res.data);
            return res.data;
        },

        vendors: {
            create: async function () {
                Vue.$log.debug("Create new vendor");
                let res = await server.post("vendors");
                Vue.$log.debug(res.data);

                // Got new vendor info, push it to the store
                Vue.user.add_vendor(res.data);
                return res.data.id;
            },
            get_full_list: async function () {
                Vue.$log.debug("Refreshing full vendors list");
                let res = await server.get('vendors');
                // Vue.$log.debug(res.data);

                for (let v of res.data) {
                    Vue.user.add_vendor(v);
                }
            },
            update: async function (vendorID, vendorInfo) {
                Vue.$log.debug(`Updating vendor ${vendorID}`);
                let res = await server.put(`/vendors/${vendorID}`, vendorInfo);
                Vue.$log.debug(res.data);
                // Update the store
                Vue.user.add_vendor(res.data);
            },
            get_all_default_tags: async function () {
                Vue.$log.debug("Refreshing full default tags list");
                let res = await server.get('vendors/default_tags');
                Vue.$log.debug(res.data);

                for (let v of res.data) {
                    Vue.user.add_default_tag(v);
                }
            },
            create_default_tag: async function (vendorID, tagID) {
                Vue.$log.debug(`Creating new vendor default tag`);
                let res = await server.post('/vendors/default_tag', {
                    vendor: vendorID,
                    tag: tagID,
                });
                Vue.user.add_default_tag(res.data);
            },
            delete_default_tag: async function (vendorID, tagID) {
                Vue.$log.debug(`Deleting vendor default tag`);
                let res = await server.delete(`/vendors/default_tag/${vendorID}/${tagID}`);

                Vue.user.delete_default_tag({
                    vendor: vendorID,
                    tag: tagID
                });
            },
        },

        tags: {
            create: async function () {
                Vue.$log.debug("Create new tag");
                let res = await server.post("tags");
                Vue.$log.debug(res.data);

                // Got new tag info, push it to the store
                Vue.user.add_tag(res.data);
                return res.data.id;
            },
            get_full_list: async function () {
                Vue.$log.debug("Refreshing full tags list");
                let res = await server.get('tags');
                // Vue.$log.debug(res.data);

                for (let v of res.data) {
                    Vue.user.add_tag(v);
                }
            },
            update: async function (tagID, tagInfo) {
                Vue.$log.debug(`Updating tag ${tagID}`);
                let res = await server.put(`/tags/${tagID}`, tagInfo);
                Vue.$log.debug(res.data);
                // Update the store
                Vue.user.add_tag(res.data);
            }
        },

        accounts: {
            create: async function () {
                Vue.$log.debug("Create new account");
                let res = await server.post("accounts");
                Vue.$log.debug(res.data);

                // Got new account info, push it to the store
                Vue.user.add_account(res.data);
                return res.data.id;
            },
            get_full_list: async function () {
                Vue.$log.debug("Refreshing full accounts list");
                let res = await server.get('accounts');
                // Vue.$log.debug(res.data);

                for (let v of res.data) {
                    Vue.user.add_account(v);
                }
            },
            update: async function (accountID, accountInfo) {
                Vue.$log.debug(`Updating account ${accountID}`);
                let res = await server.put(`/accounts/${accountID}`, accountInfo);
                Vue.$log.debug(res.data);
                // Update the store
                Vue.user.add_account(res.data);
            }
        },

        charges: {
            create: async function (info, tags) {
                Vue.$log.debug(`Creating new charge: ${info} - ${tags}`);
                let res = await server.post('/charges', {
                    info: info,
                    tags: tags,
                });
                Vue.$log.debug(res.data);

                return res.data;
            },
            get_all: async function (filters) {
                Vue.$log.debug(`Getting all charges with filters: ${filters}`);
                let res = await server.get('/charges/all', {
                    params: filters
                });
                return res.data;
            },
            get: async function (chargeID) {
                Vue.$log.debug(`Getting charge ID ${chargeID}`);
                let res = await server.get(`/charges/${chargeID}`);
                return res.data;
            },
            update: async function (chargeID, info, tags) {
                Vue.$log.debug(`Updating charge ${chargeID}`);
                let res = await server.put(`/charges/${chargeID}`, {
                    info: info,
                    tags: tags,
                });
            },
            delete: async function (chargeID) {
                Vue.$log.debug(`Deleting charge ${chargeID}`);
                let res = await server.delete(`charges/${chargeID}`);
            },
        },

        revenue: {
            create: async function (info) {
                Vue.$log.debug(`Creating new revenue item: ${info}`);
                let res = await server.post('/revenue', {
                    info: info,
                });
                Vue.$log.debug(res.data);

                return res.data;
            },
            get_all: async function (filters) {
                Vue.$log.debug(`Getting all revenue items with filters: ${JSON.stringify(filters)}`);
                let res = await server.get('/revenue/all', {
                    params: filters
                });
                return res.data;
            },
            get_all_with_totals: async function (filters) {
                Vue.$log.debug(`Getting all revenue items and totals with filters: ${JSON.stringify(filters)}`);
                let res = await server.get('/revenue/all_with_totals', {
                    params: filters
                });
                return res.data;
            },
            get: async function (revenueID) {
                Vue.$log.debug(`Getting revenue ID ${revenueID}`);
                let res = await server.get(`/revenue/${revenueID}`);
                return res.data;
            },
            update: async function (revenueID, info, tags) {
                Vue.$log.debug(`Updating revenue item ${revenueID}`);
                let res = await server.put(`/revenue/${revenueID}`, {
                    info: info,
                });
            },
            delete: async function (revenueID) {
                Vue.$log.debug(`Deleting revenue item ${revenueID}`);
                let res = await server.delete(`/revenue/${revenueID}`);
            },
        },

        payments: {
            create: async function (info) {
                Vue.$log.debug(`Creating new payment: ${info}`);
                let res = await server.post('/payments', {
                    info: info,
                });
                Vue.$log.debug(res.data);

                return res.data;
            },
            get_all: async function (filters) {
                Vue.$log.debug(`Getting all payments with filters: ${filters}`);
                let res = await server.get('/payments/all', {
                    params: filters
                });
                return res.data;
            },
            get: async function (paymentID) {
                Vue.$log.debug(`Getting payment ID ${paymentID}`);
                let res = await server.get(`/payments/${paymentID}`);
                return res.data;
            },
            update: async function (paymentID, info, tags) {
                Vue.$log.debug(`Updating payment ${paymentID}`);
                let res = await server.put(`/payments/${paymentID}`, {
                    info: info,
                });
            },
            delete: async function (paymentID) {
                Vue.$log.debug(`Deleting charge ${paymentID}`);
                let res = await server.delete(`/payments/${paymentID}`);
            },
        },

        admin: {
            import_stripe_charges: async function () {
                Vue.$log.debug(`Running Stripe charge import`);
                let res = await server.post(`/admin/import_stripe_charges`);
                return res.data;
            },
            import_square_charges: async function () {
                Vue.$log.debug(`Running Square charge import`);
                let res = await server.post(`/admin/import_square_charges`);
                return res.data;
            },
            import_mssnctrl_bookings: async function () {
                Vue.$log.debug(`Running MSSNCTRL bookings import`);
                let res = await server.post(`/admin/import_mssnctrl_bookings`);
                return res.data;
            },
        },



        initial_load: async function () {
            try {
                Vue.$log.debug("Starting initial data load");
                if (Vue.user.is_authenticated) {
                    Vue.user.empty_store();
                    await this.vendors.get_full_list();
                    await this.tags.get_full_list();
                    await this.accounts.get_full_list();
                    await this.vendors.get_all_default_tags();
                    Vue.user.initial_load_complete = true;
                } else {
                    Vue.$log.debug("Not logged in, skipping initial load");
                }
            } catch (e) {
                Vue.$log.debug("Got an error doing initial load");
                Vue.$log.debug(e);
            }

        }
    }
}



export default wrapper;
