<template lang="pug">
footer.footer
    .container
        span.has-text-centered {{authedUser}}
        .wrap.has-text-centered
            span.icon
                i.far.fa-copyright
            span {{currentYear}} tallco.de




</template>

<style scoped>
footer.footer {
    padding: 0;
    height: 60px;
}

footer > .container {
    display: grid;
    height: 100%;
    grid-auto-flow: column;
    grid-auto-rows: 100%;
    align-items: center;
}
</style>

<script>
export default {
    name: "Footer",
    computed: {
        authedUser: function () {
            if (this.$user.is_authenticated) {
                return `Logged in as: ${this.$user.info.name}`;
            } else {
                return "Not Logged In";
            }
        },
        currentYear: function () {
            return (new Date()).getFullYear();
        }
    }
}
</script>

