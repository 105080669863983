<template lang="pug">
.container.growToPage
    .title.is-4.has-text-centered Revenue Report

    .level
        .level-left
            .level-item
                label.label Date Range:
            .level-item
                b-datepicker.smallPicker(v-model="filters.startDate")
            .level-item
                span -
            .level-item
                b-datepicker.smallPicker(v-model="filters.endDate")

            .level-item.margin-left
                label.label Sort By:
            .level-item
                .select
                    select(v-model="filters.sortby")
                        option(value='date') Date
                        option(value='id') ID
                        option(value='type') Type
            .level-item
                span.sortOrderIcon.alignWithInput.fas(:class="sortOrderIcon.classes" @click="swapSortOrder")

            .level-item.margin-left
                label.label Channel:
            .level-item
                .select.smallPicker
                    select(v-model="filters.channel")
                        option(value='any') Any
                        option(value='none') N/A
                        option(v-for="c in uniqueChannels" :value="c") {{c}}


            .level-item.margin-left
                label.label Finalized:
            .level-item
                .select
                    select(v-model="filters.finalized")
                        option(value='any') Any
                        option(value='true') Yes
                        option(value='false') No

        .level-right
            .level-item
                button.button.is-danger(@click="downloadCSV") CSV EXPORT

    section.hero.is-dark(@click="toggleExtras")
        .hero-body.tinyHero
            .level.is-mobile
                .level-left
                    p.is-size-5 TOTALS
                .level-right
                    .level-item
                        span.is-size-4.fal(:class="extrasDropdown.classes")
    .remainder(v-if="extrasVisible")
        .columns
            .column.is-6
                .box
                    table.table
                        thead
                            tr
                                th Channel
                                th Gross
                                th Fees
                                th Net
                        tbody
                            tr(v-for="c in Object.keys(totalsData.channels)")
                                td {{c}}
                                td {{totalsData.channels[c].gross.toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}
                                td {{totalsData.channels[c].fees.toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}
                                td {{(totalsData.channels[c].gross - totalsData.channels[c].fees).toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}

            .column.is-6
                .box
                    table.table
                        thead
                            tr
                                th GROSS
                                th OTA FEES
                                th PROCESSING FEES
                                th NET
                        tbody
                            tr
                                td {{totalsData.totals.gross.toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}
                                td {{totalsData.totals.ota.toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}
                                td {{totalsData.totals.processing.toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}
                                td {{totalsData.totals.net.toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}


    .chargeTable
        table.table.is-fullwidth.is-striped.is-hoverable.is-narrow(v-if="!loading")
            thead
                tr
                    th Date
                    th ID
                    th Type
                    th Description
                    th Channel
                    th Booking GROSS
                    th Total GROSS
                    th Total OTA Fees
                    th Total Processing Fees
                    th Total NET
                    th Finalized
            tbody
                router-link(v-for="c in filteredItems" tag="tr" :to="'/revenue/' + c.id")
                    td.nowrap {{c.date}}
                    td {{c.id}}
                    td.firstCap {{c.type}}
                    td {{c.description}}
                    td {{c.booking_source ? c.booking_source : 'N/A'}}
                    td {{c.booking_gross ? parseInt(c.booking_gross).toLocaleString('en-JP', { style: 'currency', currency: 'JPY' }) : 'N/A'}}
                    td {{parseInt(c.total_gross).toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}
                    td {{parseInt(c.total_ota).toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}
                    td {{parseInt(c.total_processing).toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}
                    td {{calculateTotalNet(c).toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}
                    td(v-if="c.finalized")
                        span.has-text-success.fas.fa-check-circle
                    td(v-else)
                        span.has-text-danger.fas.fa-times-circle

        .field.is-grouped.is-grouped-centered(v-else)
            .control
                .loader



    p.has-text-weight-bold.has-text-danger.has-text-centered {{err}}
</template>

<style scoped>
.container.growToPage {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.chargeTable {
    margin-top: 1rem;
    max-width: 100vw;
    overflow: auto;
}
.nowrap {
    white-space: nowrap;
}
.sortOrderIcon {
    font-size: 24px;
    cursor: pointer;
}
.margin-left {
    margin-left: 1rem;
}
.smallPicker {
    max-width: 110px;
}
.tinyHero {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}
.remainder {
    border-left: 1px solid #292929;
    border-right: 1px solid #292929;
    border-bottom: 1px solid #292929;
    padding: 0.5rem;
}
</style>

<script>
export default {
    name: "RevenueReport",
    data () {
        return {
            loading: true,
            items: [],
            filters: {
                sortby: 'date',
                sortorder: true,
                finalized: 'any',
                startDate: new Date(this.$user.startDate),
                endDate: new Date(this.$user.endDate),
                channel: 'any',
            },
            err: null,
            extrasVisible: false,
        }
    },
    activated: async function () {
        await this.getItems();
    },
    methods: {
        getItems: async function () {
            this.loading = true;

            try {
                this.items = await this.$api.revenue.get_all_with_totals();
                this.$log.debug(this.items);
                this.loading = false;
            } catch (e) {
                this.$log.debug("Failed to get all revenue items");
                this.err = this.logError(e);
            }
        },
        calculateTotalNet: function (r) {
            return parseInt(r.total_gross) - parseInt(r.total_ota) - parseInt(r.total_processing);
        },
        swapSortOrder: function () {
            this.filters.sortorder = !this.filters.sortorder;
        },
        downloadCSV: function () {
            let csvContent = "data:text/csv;charset=utf-8,";

            if (this.filteredItems.length == 0) {
                this.$log.error("NO ITEMS TO EXPORT - ADJUST FILTERS");
                return;
            }

            const exportFields = ['id', 'date', 'type', 'total_gross', 'total_ota', 'total_processing'];
            csvContent += exportFields.join(',') + "\r\n";

            for (let r of this.filteredItems) {
                csvContent += exportFields.map(a => String(r[a]).replace(/(\r\n|\n|\r|,)/gm, " ")).join(',') + '\r\n';
            }

            let encodedURI = encodeURI(csvContent);

            let link = document.createElement('a');
            link.setAttribute('href', encodedURI);
            link.setAttribute('download', 'revenue_report.csv');
            document.body.appendChild(link);
            link.click();
        },
        toggleExtras: function () {
            this.extrasVisible = !this.extrasVisible;
        },
    },
    computed: {
        uniqueChannels: function () {
            return this.items.reduce((a, cv) => {
                if (cv.booking_source != null && !a.includes(cv.booking_source.toUpperCase())) {
                    a.push(cv.booking_source.toUpperCase());
                }
                return a;
            }, []);
        },
        filteredItems: function () {
            return this.items.filter((a) => {
                let ok = true;

                if (this.filters.finalized != 'any') {
                    if (a.finalized.toString() != this.filters.finalized) {
                        return false;
                    }
                }

                if (this.filters.channel != 'any') {
                    if (this.filters.channel == 'none') {
                        if (a.booking_source != null) {
                            return false;
                        }
                    } else if (a.booking_source == null || a.booking_source.toUpperCase() != this.filters.channel) {
                        return false;
                    }
                }

                let d = new Date(a.date);
                if (d.getTime() < this.filters.startDate.getTime() || d.getTime() > this.filters.endDate.getTime()) {
                    return false;
                }

                return ok;
            }).sort((a, b) => {
                let c1 = a[this.filters.sortby];
                let c2 = b[this.filters.sortby];

                if (this.filters.sortby == 'date') {
                    c1 = (new Date(a.date)).getTime();
                    c2 = (new Date(b.date)).getTime();
                }

                if (c1 > c2) {
                    return this.filters.sortorder ? -1 : 1;
                } else if (c2 > c1) {
                    return this.filters.sortorder ? 1 : -1;
                } else {
                    if (a.id > b.id) return this.filters.sortorder ? 1 : -1;
                    return 0;
                }
            });
        },
        sortOrderIcon: function () {
            return {
                classes: {
                    'fa-angle-double-up': this.filters.sortorder,
                    'has-text-success': this.filters.sortorder,
                    'fa-angle-double-down': !this.filters.sortorder,
                    'has-text-danger': !this.filters.sortorder,
                }
            }
        },
        extrasDropdown: function () {
            return {
                classes: {
                    'fa-angle-double-down': !this.extrasVisible,
                    'fa-angle-double-up': this.extrasVisible,
                }
            }
        },
        totalsData: function () {

            let channels = {
                other: {
                    gross: 0,
                    fees: 0,
                }
            };
            let totals = {
                gross: 0,
                ota: 0,
                processing: 0,
            };
            for (let b of this.filteredItems) {
                if (b.booking_source != null){
                    b.booking_source = b.booking_source.toUpperCase();
                    if (channels[b.booking_source] != null) {
                        channels[b.booking_source].gross += parseInt(b.total_gross);
                        channels[b.booking_source].fees += parseInt(b.total_ota);
                    } else {
                        channels[b.booking_source] = {
                            gross: parseInt(b.total_gross),
                            fees: parseInt(b.total_ota),
                        };
                    }
                } else {
                    channels.other.gross += parseInt(b.total_gross);
                    channels.other.fees += parseInt(b.total_ota);
                }
                totals.gross += parseInt(b.total_gross);
                totals.ota += parseInt(b.total_ota);
                totals.processing += parseInt(b.total_processing);
            }

            totals.net = totals.gross - totals.ota - totals.processing;

            return {
                channels,
                totals,
            }
        },
    }
}
</script>

