import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Index from '../components/Index';
import Revenue from '../components/Revenue';
import NotFound from '../components/NotFound';
import Login from '../components/Login';
import Config from '../components/config/Config';
import ChargeReport from '../components/reports/ChargeReport';
import RevenueReport from '../components/reports/RevenueReport';
import Payments from "../components/Payments";
import PaymentReport from "../components/reports/PaymentReport";

const ifNotAuthenticated = (to, from, next) => {
    if (!Vue.user.is_authenticated) {
        next();
    } else {
        next('/charge');
    }
};

const ifAuthenticated = (to, from, next) => {
    if (Vue.user.is_authenticated) {
        next();
    } else {
        next('/');
    }
};

const routes = [
    {
        path: '/charge',
        component: Index,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/charge/:chargeID',
        component: Index,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/revenue',
        component: Revenue,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/revenue/:revenueID',
        component: Revenue,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/payments',
        component: Payments,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/payments/:paymentID',
        component: Payments,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/config',
        component: Config,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/reports/charges',
        component: ChargeReport,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/reports/revenue',
        component: RevenueReport,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/reports/payments',
        component: PaymentReport,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/',
        component: Login,
        beforeEnter: ifNotAuthenticated
    },
    {
        path: '*',
        component: NotFound,
        beforeEnter: ifAuthenticated
    }
];

export default new Router({
    mode: 'history',
    routes
});
