<template lang="pug">
.container.growToPage
    .field.is-grouped.is-grouped-centered.extrapad
            h3.title.is-3 Configuration

    .tabs.is-boxed.is-centered
        ul
            li(:class="isActiveTab('vendors')")
                a(@click="changeTab('vendors')")
                    span.icon.is-small
                        i.fas.fa-store-alt
                    span Vendors
            li(:class="isActiveTab('tags')")
                a(@click="changeTab('tags')")
                    span.icon.is-small
                        i.fas.fa-tags
                    span Tags
            li(:class="isActiveTab('accounts')")
                a(@click="changeTab('accounts')")
                    span.icon.is-small
                        i.fas.fa-yen-sign
                    span Accounts
            li(:class="isActiveTab('admin')")
                a(@click="changeTab('admin')")
                    span.icon.is-small
                        i.fas.fa-skull-crossbones
                    span Admin
    component(:is="activeTab")

    p.err

</template>

<style scoped>
.extrapad {
    padding: 1rem;
}
</style>

<script>
import Vendors from './Vendors';
import Tags from './Tags';
import Accounts from './Accounts';
import Admin from './Admin';

export default {
    name: "Config",
    data: function () {
        return {
            tab: 'vendors'

        }
    },
    components: {
        'tab-vendors': Vendors,
        'tab-tags': Tags,
        'tab-accounts': Accounts,
        'tab-admin': Admin
    },
    methods: {
        isActiveTab: function (tab) {
            return {
                'is-active': this.tab == tab
            }
        },
        changeTab: function (tab) {
            this.tab = tab;
        }
    },
    computed: {
        activeTab: function () {
            return `tab-${this.tab}`;
        }
    }
}
</script>

