<template lang="pug">
.navbar.is-primary
    .container
        .navbar-brand
            router-link.navbar-item(to="/") {{brand}}
            .navbar-burger(@click="triggerMobileNav", :class="mobileNavActive")
                span
                span
                span
        .navbar-menu(:class="mobileNavActive")
            .navbar-start
            template(v-if="loggedIn")
                .navbar-end
                    router-link.navbar-item(to="/charge" @click.native="triggerMobileNav") Charge
                    router-link.navbar-item(to="/revenue" @click.native="triggerMobileNav") Revenue
                    router-link.navbar-item(to="/payments" @click.native="triggerMobileNav") Payment
                    .navbar-item.has-dropdown.is-hoverable
                        .navbar-link Reports
                        .navbar-dropdown
                            router-link.navbar-item(to="/reports/charges" @click.native="triggerMobileNav") Charges
                            router-link.navbar-item(to="/reports/revenue" @click.native="triggerMobileNav") Revenue
                            router-link.navbar-item(to="/reports/payments" @click.native="triggerMobileNav") Payments
                    router-link.navbar-item(to="/config" @click.native="triggerMobileNav") Config
                    a.navbar-item(@click="logout") Log Out
            template(v-else)
                .navbar-end
                    a.navbar-item Contact
</template>

<style scoped>

</style>

<script>
export default {
    name: "Navigation",
    data: function () {
        return {
            brand: "SNOWBILLS",
            mobileNavActive: {
                'is-active': false
            },
        }
    },
    methods: {
        triggerMobileNav: function () {
            this.mobileNavActive['is-active'] = !this.mobileNavActive['is-active'];
        },
        logout: async function () {
            await this.$api.logout();
            this.triggerMobileNav();
            this.$router.push('/');
        }
    },
    computed: {
        loggedIn: function () {
            return this.$user.is_authenticated;
        }
    }
}
</script>

