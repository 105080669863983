<template lang="pug">
.container.growToPage
    .columns
        .column.is-half.is-offset-one-quarter
            .box
                .field(v-if="isExistingItem")
                    .title.is-4.has-text-centered Editing Revenue Item \#{{$route.params.revenueID}}
                    .field.is-grouped.is-grouped-centered(v-if="!getExistingPending && !revenue.finalized")
                        button.button.is-small.is-danger(:disabled="revenue.finalized" :class="deleteButton.classes" @click="deleteItem") DELETE THIS REVENUE ITEM
                    .field(v-if="revenue.finalized")
                        p.has-text-weight-bold.has-text-centered.has-text-danger FINALIZED
                .title.is-4.has-text-centered(v-else) Create a Revenue Item

                hr
                form(v-if="!getExistingPending" @submit.prevent="pushItem")
                    b-field(label="Date")
                        b-datepicker(:disabled="revenue.finalized" placeholder="Not set" v-model="revenue.date")
                    .field
                        label.label Description
                        .control
                            input.input(:disabled="revenue.finalized" required type="text" placeholder="Not set" v-model="revenue.description")
                    .field
                        label.label Type
                        .control.is-expanded
                            .select.is-fullwidth
                                select(:disabled="revenue.finalized" required v-model="revenue.type")
                                    option(value='booking') Booking
                                    option(value='commission') Commission
                                    option(value='other') Other
                    .field
                        label.label Extra Notes
                        .control
                            textarea.textarea(:disabled="revenue.finalized" placeholder="Not set" v-model="revenue.notes")
                    .field
                        label.label Finalized
                    .field
                        .switchLabel No
                        b-switch(v-model="revenue.finalized" size="is-small")
                        .switchLabel Yes


                    .field(v-if="revenue.booking_id")
                        label.label.has-text-grey Booking ID
                        .control
                            input.input.is-static.underlined-fields(readonly required type="text" placeholder="Not set" v-model="revenue.booking_id")
                    .field(v-if="revenue.booking_id")
                        label.label.has-text-grey Booking Gross
                        .control
                            input.input.is-static.underlined-fields(readonly required type="text" placeholder="Not set" :value="revenue.booking_gross ? parseInt(revenue.booking_gross).toLocaleString('en-JP', { style: 'currency', currency: 'JPY' }) : ''")
                    .field(v-if="revenue.booking_id")
                        label.label.has-text-grey Booking Source
                        .control
                            input.input.is-static.underlined-fields(readonly required type="text" placeholder="Not set" v-model="revenue.booking_source")
                    .field(v-if="revenue.booking_id")
                        label.label.has-text-grey Booking Notes
                        .control
                            textarea.textarea(disabled placeholder="Not set" v-model="revenue.booking_notes" rows="7")


                    .field.is-grouped.is-grouped-centered
                        button.button.is-danger(type="submit" :class="submitButton.classes") {{submitButton.text}}

                    p.has-text-centered.has-text-success.has-text-weight-bold(v-if="showSuccess") SUCCESS!
                .field.is-grouped.is-grouped-centered(v-else)
                    .control
                        .loader
                p.has-text-danger.has-text-centered.has-text-weight-bold {{err}}

                div(v-if="isExistingItem && !getExistingPending")
                    hr

                    .field(v-if="isExistingItem")
                        .title.is-4.has-text-centered Linked Payments

                    table.table.is-fullwidth.is-striped.is-hoverable.is-narrow
                        thead
                            tr
                                th ID
                                th Date
                                th.has-text-right GROSS
                                th.has-text-right OTA Fees
                                th.has-text-right Processing Fees
                                th.has-text-right NET

                        tbody
                            router-link.hover-clickable(v-for="c in payments" tag="tr" :to="'/payments/' + c.id")
                                td {{c.id}}
                                td {{c.date}}
                                td.has-text-right {{c.gross.toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}
                                td.has-text-right {{c.ota_fee.toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}
                                td.has-text-right {{c.processing_fee.toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}
                                td.has-text-right {{calculateNetFromPayment(c).toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}
                        tfoot
                            tr
                                td
                                td.has-text-weight-bold TOTALS
                                td.has-text-right {{paymentTotals.gross.toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}
                                td.has-text-right {{paymentTotals.ota_fee.toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}
                                td.has-text-right {{paymentTotals.processing_fee.toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}
                                td.has-text-right {{calculateNetFromPayment(paymentTotals).toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}

                    .field.is-grouped.is-grouped-centered(v-if="!revenue.finalized")
                        a.button.is-link(:href="`/payments?revenue_id=${$route.params.revenueID}`" target="_blank") ADD A PAYMENT



</template>

<style scoped>
.container.growToPage {
    padding: 0.5rem;
}
.switchLabel {
    display: inline-block;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.underlined-fields {
    border-bottom: 1px solid #dbdbdb;
}
.hover-clickable {
    cursor: pointer;
}

</style>

<script>
import BSwitch from "buefy/src/components/switch/Switch";
export default {
    name: "Revenue",
    components: {BSwitch},
    data () {
        return {
            submitPending: false,
            revenue: {
                type: 'booking',
                description: null,
                notes: null,
                finalized: false,
                date: new Date(),
                booking_id: null,
                booking_gross: null,
                booking_notes: null,
                booking_source: null,
            },
            payments: [],
            err: null,
            showSuccess: false,
            isExistingItem: false,
            getExistingPending: false,
            updatePending: false,
            deletePending: false,
        }
    },
    activated: async function () {
        await this.recheckRoute();
    },
    watch: {
        '$route.params.revenueID': function () {
            this.recheckRoute();
        }
    },
    methods: {
        recheckRoute: async function () {
            this.err = null;
            this.resetFields();
            this.isExistingItem = false;
            if (!isNaN(parseInt(this.$route.params.revenueID))) {
                this.isExistingItem = true;
                await this.getExistingItem(this.$route.params.revenueID);
            }
        },
        getExistingItem: async function (revenueID) {
            if (this.getExistingPending) return;
            this.getExistingPending = true;
            this.err = null;
            try {
                let r = await this.$api.revenue.get(revenueID);
                this.$log.debug(`Got response for revenue item`);
                this.$log.debug(r);
                this.payments = r.payments;
                for (let k of Object.keys(this.revenue)) {
                    if (k == 'date') {
                        this.revenue.date = new Date(r.info.date);
                    } else {
                        this.revenue[k] = r.info[k];
                    }
                }
                this.getExistingPending = false;
            } catch (e) {
                this.$log.debug("Failed to get existing revenue item");
                this.err = this.logError(e);
                this.getExistingPending = false;
            }
        },
        formatDate: function (date) {
            let d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        },
        updateExistingItem: async function () {
            if (this.updatePending) return;

            this.updatePending = true;
            this.err = null;
            try {
                let infoCopy = JSON.parse(JSON.stringify(this.revenue));
                infoCopy.date = this.formatDate(infoCopy.date);

                let r = await this.$api.revenue.update(this.$route.params.revenueID, infoCopy);
                this.triggerSuccess();

                this.updatePending = false;
            } catch (e) {
                this.$log.debug("Failed to update revenue item");
                this.err = this.logError(e);
                this.updatePending = false;
            }
        },
        createItem: async function () {
            if (this.submitPending) return;

            this.submitPending = true;
            this.err = null;
            try {
                let infoCopy = JSON.parse(JSON.stringify(this.revenue));
                infoCopy.date = this.formatDate(infoCopy.date);

                let r = await this.$api.revenue.create(infoCopy);
                this.triggerSuccess();
                // Reset all the fields
                this.resetFields();

                this.$router.push(`/revenue/${r.id}`);

                this.submitPending = false;
            } catch (e) {
                this.$log.debug("Failed to create new revenue item");
                this.err = this.logError(e);
                this.submitPending = false;
            }
        },
        triggerSuccess: async function () {
            this.showSuccess = true;
            await this.waitSecondsAsync(2);
            this.showSuccess = false;
        },
        pushItem: async function () {
            if (this.isExistingItem) {
                this.updateExistingItem();
            } else {
                this.createItem();
            }
        },
        deleteItem: async function () {
            if (this.deletePending) return;

            this.deletePending = true;
            this.err = null;
            try {
                await this.$api.revenue.delete(this.$route.params.revenueID);
                // Reset all the fields
                this.resetFields();
                // Redirect to the report page
                this.$router.push('/reports/revenue');
                this.deletePending = false;
            } catch (e) {
                this.$log.debug("Failed to delete revenue item");
                this.err = this.logError(e);
                this.deletePending = false;
            }
        },
        resetFields: function () {
            this.payments = [];
            this.revenue = {
                type: 'booking',
                description: null,
                notes: null,
                finalized: false,
                date: new Date(),
                booking_id: null,
                booking_gross: null,
                booking_notes: null,
                booking_source: null,
            };
        },

    },
    computed: {
        submitButton: function () {
            return {
                classes: {
                    'is-loading': this.submitPending || this.updatePending
                },
                text: this.isExistingItem ? 'Update' : 'Submit'
            }

        },
        deleteButton: function () {
            return {
                classes: {
                    'is-loading': this.deletePending
                }
            }
        },
        paymentTotals: function () {
            return {
                gross: this.payments.reduce((a, cv) => a + cv.gross, 0),
                ota_fee: this.payments.reduce((a, cv) => a + cv.ota_fee, 0),
                processing_fee: this.payments.reduce((a, cv) => a + cv.processing_fee, 0),
            }
        }
    }
}
</script>
