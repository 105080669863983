<template lang="pug">
#app
    #nav
        navigation
    #innerContent
        keep-alive
            router-view
    #foot
        sbfooter
</template>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Add custom styling here
html, body {
    height: 100%;
    margin: 0;
}

#app {
    display: flex;
    flex-flow: column;
    height: 100%;
}
#nav {
    flex: 0 1 auto;
}
#innerContent {
    flex: 1 1 auto;
}
#foot {
    flex: 0 1 60px;
}

img {
    display: block;
}

.container.growToPage {
    height: 100%;
}
.firstCap {
    text-transform: capitalize;
}
.alignWithInput {
    line-height: 36px;
}

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>

<script>
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
export default {
    name: "app",
    components: {sbfooter: Footer, Navigation},
    data () {
        return {
            msg: 'Welcome to the VUE JS template'
        }
    },
    methods: {

    }
}
</script>
