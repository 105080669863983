<template lang="pug">
.container
    .columns(v-if="storeLoaded")
        .column.is-3
            nav.panel
                p.panel-heading Vendor List
                a.panel-block(v-for="v in $user.sorted_vendor_ids" :class="isActive(v)" @click="swapActive(v)")
                    span {{$user.vendors[v].name || `New Vendor #${v}`}}
                a.panel-block
                    button.button.is-primary.is-fullwidth(@click="createNewVendor" :class="{'is-loading': createPending}") Create New Vendor

        .column.is-9
            .box(v-if="activeItem.id != null")
                .level
                    .level-left
                        .level-item
                            .temp
                                h4.title.is-4 {{activeItem.name || `New Vendor`}}
                                p.has-text-weight-bold {{`ID: #${activeItem.id}`}}
                    .level-right
                        .level-item
                            button.button.is-small(:class="editButton.classes" @click="toggleEditing") {{editButton.text}}
                hr
                .field
                    label.label Name
                    .control
                        input.input(:readonly="!editing" type="text" v-model="activeItemCopy.name" :class="formEditableClasses" placeholder="Not set")
                .columns
                    .column.is-8
                        .field
                            label.label Default Tags
                        .field.is-grouped.is-grouped-multiline
                            .control(v-for="t in activeDefaultTags")
                                .tags.has-addons
                                    .tag.is-dark {{$user.tags[t.tag].name}}
                                    .tag.is-delete(@click="deleteDefaultTag(t.tag)")

                    .column.is-4
                        .field
                            label.label Add tag
                        .field.has-addons
                            .control.is-expanded
                                .select.is-fullwidth
                                    select(v-model="selectedTag")
                                        option(v-for="t in $user.sorted_tag_ids" :value="t") {{$user.tags[t].name || `tag#${t}`}}
                            .control
                                button.button.is-primary(:class="addButton.classes" @click="addDefaultTag") Add


            p.has-text-danger.has-text-centered.has-text-weight-bold {{err}}

    .field.is-grouped.is-grouped-centered(v-else)
        .control
            .loader


</template>

<style scoped>
.underlinedFields {
    border-bottom: 1px solid #dbdbdb;
}

@media screen and (max-width: 769px) {
    .container {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

</style>

<script>
export default {
    name: "Vendors",
    data: function () {
        return {
            createPending: false,
            activeItemId: null,
            activeItemCopy: {},
            editing: false,
            editPending: false,
            err: null,
            addDefaultPending: false,
            selectedTag: null,
            deleteDefaultPending: false,
        }
    },
    mounted: function () {
        // If the store is already loaded we need to copy the item
        if (this.storeLoaded) {
            this.swapActive(this.activeItemId);
        }
    },
    watch: {
        storeLoaded: function (is, was) {
            this.swapActive(this.activeItemId);
        }
    },
    methods: {
        createNewVendor: async function () {
            if (this.createPending) return;

            this.createPending = true;
            this.err = null;
            try {
                let newID = await this.$api.vendors.create();
                // Make it the new default
                this.swapActive(newID);

                this.createPending = false;
            } catch (e) {
                this.$log.debug('Failed to create new vendor');
                this.err = this.logError(e);
                this.createPending = false;
            }
        },
        isActive: function (i) {
            return {
                'is-active': i == this.activeItemId
            }
        },
        swapActive: function (i) {
            if (this.editPending) return;

            if (this.$user.sorted_vendor_ids.length > 0) {
                this.editing = false;

                if (i == null) {
                    this.activeItemId = this.$user.sorted_vendor_ids[0];
                } else {
                    this.activeItemId = i;
                }
                this.activeItemCopy = JSON.parse(JSON.stringify(this.$user.vendors[this.activeItemId]));
            }

            if (this.$user.sorted_tag_ids.length > 0) {
                this.selectedTag = this.$user.sorted_tag_ids[0];
            }
        },
        toggleEditing: async function () {
            if (this.editPending) return;

            this.editing = !this.editing;
            if (!this.editing) {
                this.err = null;
                this.editPending = true;
                try {
                    await this.$api.vendors.update(this.activeItemId, this.activeItemCopy);
                } catch (e) {
                    this.$log.debug('Failed to update vendor');
                    this.err = this.logError(e);
                }
                this.editPending = false;
            }
        },
        addDefaultTag: async function () {
            if (this.addDefaultPending) return;

            try {
                this.err = null;
                this.addDefaultPending = true;
                await this.$api.vendors.create_default_tag(this.activeItemId, this.selectedTag);
            } catch (e) {
                this.$log.debug('Failed to create new vendor default tag');
                this.err = this.logError(e);
            }
            this.addDefaultPending = false;
        },
        deleteDefaultTag: async function (tagID) {
            if (this.deleteDefaultPending) return;

            try {
                this.err = null;
                this.deleteDefaultPending = true;
                await this.$api.vendors.delete_default_tag(this.activeItemId, tagID);
            } catch (e) {
                this.$log.debug('Failed to delete vendor default tag');
                this.err = this.logError(e);
            }
            this.deleteDefaultPending = false;
        }

    },
    computed: {
        activeItem: function () {
            return this.$user.vendors[this.activeItemId] || {};
        },
        activeDefaultTags: function () {
            return this.$user.default_tags.filter((t) => t.vendor == this.activeItemId);
        },
        formEditableClasses: function () {
            return {
                'is-static': !this.editing,
                'underlinedFields': !this.editing
            }
        },
        editButton: function () {
            return {
                classes: {
                    'is-info': (!this.editing && !this.editPending),
                    'is-danger': (this.editing || this.editPending),
                    'is-loading': this.editPending
                },
                text: this.editing ? 'SAVE' : 'EDIT'
            }
        },
        storeLoaded: function () {
            return this.$user.initial_load_complete;
        },
        addButton: function () {
            return {
                classes: {
                    'is-loading': this.addDefaultPending
                }
            }
        }
    }
}
</script>

