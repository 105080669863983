<template lang="pug">
.container.growToPage
    .columns
        .column.is-half.is-offset-one-quarter
            .box
                .field(v-if="isExistingItem")
                    .title.is-4.has-text-centered Editing Payment \#{{$route.params.paymentID}}
                    .field.is-grouped.is-grouped-centered(v-if="!getExistingPending && !formDisabled")
                        button.button.is-small.is-danger(:disabled="formDisabled" :class="deleteButton.classes" @click="deleteItem") DELETE THIS PAYMENT
                    .field(v-if="formDisabled")
                        p.has-text-weight-bold.has-text-centered.has-text-danger FINALIZED
                .title.is-4.has-text-centered(v-else) Create a Payment

                hr
                form(v-if="!getExistingPending" @submit.prevent="pushItem")
                    b-field(label="Date")
                        b-datepicker(placeholder="Not set" :disabled="formDisabled" v-model="payment.date")

                    .field(v-if="!revenueLocked")
                        label.label Revenue Item
                        .control.is-expanded
                            .select.is-fullwidth
                                select(required v-model="payment.revenue_id")
                                    option(v-for="x in unfinalizedRevenueItems" :value="x.id") {{`#${x.id} - ${x.description}`}}

                    .field(v-else)
                        label.label Revenue Item
                        .field.is-grouped
                            .control.is-expanded
                                input.input.is-static.underlined-fields(disabled type="text" :value="`#${payment.revenue_id}`")
                            .control
                                button.button.is-danger(:disabled="formDisabled" type="button" @click="editRevenueItem" :class="editRevenueButton.classes") CHANGE
                            .control
                                router-link.button.is-link(tag="button" :to="`/revenue/${payment.revenue_id}`") JUMP TO

                    .field
                        label.label Gross
                        .control
                            input.input(required :disabled="formDisabled" :class="editableField" type="number" placeholder="Not set" v-model="payment.gross")
                    .field
                        label.label OTA Fees
                        .field.is-grouped
                            .control.is-expanded
                                input.input(required :disabled="formDisabled" :class="editableField" type="number" placeholder="Not set" v-model="payment.ota_fee")
                            .control
                                button.button.is-link(:disabled="formDisabled" type="button" @click="calculateOTAFee(0.12)") 12%
                    .field
                        label.label Processing Fees
                        .control
                            input.input(required :disabled="formDisabled" :class="editableField" type="number" placeholder="Not set" v-model="payment.processing_fee")
                    .field
                        label.label Net
                        .control
                            input.input.is-static.underlined-fields(readonly :disabled="formDisabled" type="text" placeholder="Not set" :value="calculateNetFromPayment(payment).toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })")

                    .field
                        label.label Source
                        .control.is-expanded
                            .select.is-fullwidth
                                select(:disabled="formDisabled" required v-model="payment.source")
                                    option(value='stripe') Stripe
                                    option(value='square') Square
                                    option(value='transferwise') Transferwise
                                    option(value='bank') Bank Transfer
                                    option(value='cash') Cash
                                    option(value='ota') OTA

                    .field
                        label.label Source ID
                        .control
                            input.input(:disabled="formDisabled" :class="editableField" type="text" placeholder="Not set" v-model="payment.src_id")

                    .field
                        label.label Extra Notes
                        .control
                            textarea.textarea(:disabled="formDisabled" placeholder="Not set" v-model="payment.notes")


                    .field.is-grouped.is-grouped-centered(v-if="!formDisabled")
                        button.button.is-danger(:disabled="formDisabled" type="submit" :class="submitButton.classes") {{submitButton.text}}

                    p.has-text-centered.has-text-success.has-text-weight-bold(v-if="showSuccess") SUCCESS!
                .field.is-grouped.is-grouped-centered(v-else)
                    .control
                        .loader
                p.has-text-danger.has-text-centered.has-text-weight-bold {{err}}

</template>

<style scoped>
.container.growToPage {
    padding: 0.5rem;
}
.underlined-fields {
    border-bottom: 1px solid #dbdbdb;
}

</style>

<script>
import BSwitch from "buefy/src/components/switch/Switch";
export default {
    name: "Payments",
    components: {BSwitch},
    data () {
        return {
            submitPending: false,
            payment: {
                date: new Date(),
                revenue_id: null,
                gross: 0,
                ota_fee: 0,
                processing_fee: 0,
                source: null,
                src_id: null,
                notes: null,
            },
            revenue: {},
            unfinalizedRevenueItems: [],
            revenueLocked: false,
            err: null,
            showSuccess: false,
            isExistingItem: false,
            getExistingPending: false,
            updatePending: false,
            deletePending: false,
            revenueItemsLoading: false,
        }
    },
    activated: async function () {
        await this.recheckRoute();
    },
    watch: {
        '$route.params.paymentID': function () {
            this.recheckRoute();
        }
    },
    methods: {
        recheckRoute: async function () {
            this.err = null;
            this.resetFields();
            if (!isNaN(parseInt(this.$route.params.paymentID))) {
                this.isExistingItem = true;
                this.getExistingItem(this.$route.params.paymentID);
            } else {
                // Check for a prefill revenue ID
                if (!isNaN(parseInt(this.$route.query.revenue_id))) {
                    this.payment.revenue_id = parseInt(this.$route.query.revenue_id);
                    this.revenueLocked = true;
                } else {
                    await this.loadRevenueItems();
                }
                this.isExistingItem = false;
            }
        },
        getExistingItem: async function (paymentID) {
            if (this.getExistingPending) return;
            this.getExistingPending = true;
            this.err = null;
            try {
                let r = await this.$api.payments.get(paymentID);
                this.$log.debug(`Got response for payment`);
                this.$log.debug(r);
                this.customTags = r.tags;
                this.revenue = r.revenue;
                for (let k of Object.keys(this.payment)) {
                    if (k == 'date') {
                        this.payment.date = new Date(r.info.date);
                    } else {
                        this.payment[k] = r.info[k];
                    }
                }
                if (this.payment.revenue_id == null) {
                    // Get revenue items
                    await this.loadRevenueItems();
                } else {
                    this.revenueLocked = true;
                }

                this.getExistingPending = false;
            } catch (e) {
                this.$log.debug("Failed to get existing payment");
                this.err = this.logError(e);
                this.getExistingPending = false;
            }
        },
        formatDate: function (date) {
            let d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        },
        updateExistingItem: async function () {
            if (this.updatePending) return;

            this.updatePending = true;
            this.err = null;
            try {
                let infoCopy = JSON.parse(JSON.stringify(this.payment));
                infoCopy.date = this.formatDate(infoCopy.date);

                let r = await this.$api.payments.update(this.$route.params.paymentID, infoCopy);
                this.triggerSuccess();

                if (this.payment.revenue_id != null) {
                    this.$router.push(`/revenue/${this.payment.revenue_id}`);
                }

                this.updatePending = false;
            } catch (e) {
                this.$log.debug("Failed to update payment");
                this.err = this.logError(e);
                this.updatePending = false;
            }
        },
        createItem: async function () {
            if (this.submitPending) return;

            this.submitPending = true;
            this.err = null;
            try {
                let infoCopy = JSON.parse(JSON.stringify(this.payment));
                infoCopy.date = this.formatDate(infoCopy.date);

                let r = await this.$api.payments.create(infoCopy);
                this.triggerSuccess();
                // Reset all the fields
                this.resetFields();

                this.$router.push(`/revenue/${r.revenue_id}`);

                this.submitPending = false;
            } catch (e) {
                this.$log.debug("Failed to create new payment");
                this.err = this.logError(e);
                this.submitPending = false;
            }
        },
        triggerSuccess: async function () {
            this.showSuccess = true;
            await this.waitSecondsAsync(2);
            this.showSuccess = false;
        },
        pushItem: async function () {
            if (this.isExistingItem) {
                this.updateExistingItem();
            } else {
                this.createItem();
            }
        },
        deleteItem: async function () {
            if (this.deletePending) return;

            this.deletePending = true;
            this.err = null;
            try {
                await this.$api.payments.delete(this.$route.params.paymentID);
                // Reset all the fields
                this.resetFields();
                // Redirect to the report page
                this.$router.push('/reports/payments');
                this.deletePending = false;
            } catch (e) {
                this.$log.debug("Failed to delete payment");
                this.err = this.logError(e);
                this.deletePending = false;
            }
        },
        resetFields: function () {
            this.revenueLocked = false;
            this.unfinalizedRevenueItems = [];
            this.revenue = {};
            this.payment = {
                date: new Date(),
                revenue_id: null,
                gross: 0,
                ota_fee: 0,
                processing_fee: 0,
                source: null,
                src_id: null,
                notes: null,
            };
        },
        editRevenueItem: async function () {
            if (await this.loadRevenueItems()) {
                this.revenueLocked = false;
            }
        },
        loadRevenueItems: async function () {
            if (this.revenueItemsLoading) return;
            this.revenueItemsLoading = true;

            this.err = null;
            try {
                this.unfinalizedRevenueItems = await this.$api.revenue.get_all({
                    finalized: false
                });
                this.$log.debug(this.unfinalizedRevenueItems);
                this.revenueItemsLoading = false;
                return true;
            } catch (e){
                this.$log.debug("Failed to get unfinalized revenue items");
                this.err = this.logError(e);
                this.revenueItemsLoading = false;
            }
        },
        calculateOTAFee: function (rate) {
            this.payment.ota_fee = Math.round(parseInt(this.payment.gross) * rate);
        }
    },
    computed: {
        formDisabled: function () {
            return this.revenue.finalized;
        },
        submitButton: function () {
            return {
                classes: {
                    'is-loading': this.submitPending || this.updatePending
                },
                text: this.isExistingItem ? 'Update' : 'Submit'
            }

        },
        deleteButton: function () {
            return {
                classes: {
                    'is-loading': this.deletePending
                }
            }
        },
        editRevenueButton: function () {
            return {
                classes: {
                    'is-loading': this.revenueItemsLoading
                }
            }
        },
        editableField: function () {
            return {
                'is-static': this.formDisabled,
                'underlined-fields': this.formDisabled
            };
        }
    }
}
</script>
