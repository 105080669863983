<template lang="pug">
.container
    .columns
        .column.is-3
            nav.panel
                p.panel-heading User List?
                a.panel-block
                    button.button.is-primary.is-fullwidth(disabled) Create New User

        .column.is-9
            .box
                h4.title.is-4 ADMIN STUFF
                p.has-text-danger.has-text-weight-bold DONT TOUCH UNLESS YOU'RE COEN

                br
                br

                .field
                    .control
                        button.button.is-danger(:class="stripeImportButton.classes" @click="runStripeChargeImport") RUN STRIPE CHARGE IMPORT
                .field
                    .control
                        button.button.is-warning(:class="squareImportButton.classes" @click="runSquareChargeImport") RUN SQUARE CHARGE IMPORT
                .field
                    .control
                        button.button.is-link(:class="mssnctrlImportButton.classes" @click="runMssnctrlImport") RUN MSSNCTRL BOOKING IMPORT


                p.has-text-danger.has-text-centered.has-text-weight-bold {{err}}


</template>

<style scoped>
.underlinedFields {
    border-bottom: 1px solid #dbdbdb;
}

@media screen and (max-width: 769px) {
    .container {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

</style>

<script>
export default {
    name: "Admin",
    data: function () {
        return {
            stripeImportPending: false,
            squareImportPending: false,
            mssnctrlImportPending: false,
            err: null,
        }
    },
    mounted: function () {

    },
    methods: {
        runStripeChargeImport: async function () {
            if (this.stripeImportPending) return;

            this.err = null;
            this.stripeImportPending = true;

            try {
                this.items = await this.$api.admin.import_stripe_charges();
                this.$log.debug(this.items);
                this.stripeImportPending = false;
            } catch (e) {
                this.$log.debug("Failed to import stripe payments");
                this.err = this.logError(e);
                this.stripeImportPending = false;
            }
        },
        runSquareChargeImport: async function () {
            if (this.squareImportPending) return;

            this.err = null;
            this.squareImportPending = true;

            try {
                this.items = await this.$api.admin.import_square_charges();
                this.$log.debug(this.items);
                this.squareImportPending = false;
            } catch (e) {
                this.$log.debug("Failed to import square payments");
                this.err = this.logError(e);
                this.squareImportPending = false;
            }
        },
        runMssnctrlImport: async function () {
            if (this.mssnctrlImportPending) return;

            this.err = null;
            this.mssnctrlImportPending = true;

            try {
                this.items = await this.$api.admin.import_mssnctrl_bookings();
                this.$log.debug(this.items);
                this.mssnctrlImportPending = false;
            } catch (e) {
                this.$log.debug("Failed to import mssnctrl bookings");
                this.err = this.logError(e);
                this.mssnctrlImportPending = false;
            }
        },
    },
    computed: {
        formEditableClasses: function () {
            return {
                'is-static': !this.editing,
                'underlinedFields': !this.editing
            }
        },
        stripeImportButton: function () {
            return {
                classes: {
                    'is-loading': this.stripeImportPending
                },
            }
        },
        squareImportButton: function () {
            return {
                classes: {
                    'is-loading': this.squareImportPending
                },
            }
        },
        mssnctrlImportButton: function () {
            return {
                classes: {
                    'is-loading': this.mssnctrlImportPending
                },
            }
        },
    }
}
</script>

