<template lang="pug">
#mainblock
    h1 404 - Not Found!
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style scoped>

</style>