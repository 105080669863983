<template lang="pug">
.container.growToPage
    .columns
        .column.is-half.is-offset-one-quarter
            .box
                .field(v-if="isExistingCharge")
                    .title.is-4.has-text-centered Editing charge \#{{$route.params.chargeID}}
                    .field.is-grouped.is-grouped-centered
                        button.button.is-small.is-danger(:class="deleteButton.classes" @click="deleteCharge") DELETE THIS CHARGE
                .title.is-4.has-text-centered(v-else) Create a Charge

                hr
                form(v-if="!getExistingPending" @submit.prevent="pushCharge")
                    b-field(label="Date")
                        b-datepicker(placeholder="Not set" v-model="charge.charge_date")
                    .field
                        label.label Item
                        .control
                            input.input(required type="text" placeholder="Not set" v-model="charge.item")
                    .field
                        label.label Amount (¥)
                        .control
                            input.input(required type="number" min="0" placeholder="Not set" v-model="charge.amount")
                    .field
                        label.label Account
                        .control.is-expanded
                            .select.is-fullwidth
                                select(required v-model="charge.account")
                                    option(v-for="a in $user.sorted_account_ids" :value="a") {{$user.accounts[a].name}}
                    .field
                        label.label Vendor
                        .control.is-expanded
                            .select.is-fullwidth
                                select(required v-model="charge.vendor")
                                    option(v-for="v in $user.sorted_vendor_ids" :value="v") {{$user.vendors[v].name}}
                    .field
                        label.label Tags
                    .field.is-grouped.is-grouped-multiline(v-if="fullTagList.length > 0")
                        .control(v-for="t in fullTagList")
                            .tags.has-addons
                                .tag.is-dark {{$user.tags[t].name}}
                                .tag.is-delete(@click="deleteTag(t)")
                    .field(v-else)
                        p No tags

                    .field
                        label.label Add extra tags
                    .field.has-addons
                        .control.is-expanded
                            .select.is-fullwidth
                                select(v-model="selectedTag")
                                    option(v-for="t in $user.sorted_tag_ids" :value="t") {{$user.tags[t].name || `tag#${t}`}}
                        .control
                            button.button.is-primary(@click.prevent="addCustomTag") Add
                    .field
                        label.label Extra Notes
                        .control
                            textarea.textarea(placeholder="Not set" v-model="charge.notes")

                    .field
                        label.label Have receipt
                    .field
                        .switchLabel No
                        b-switch(v-model="charge.have_receipt" size="is-small")
                        .switchLabel Yes


                    .field.is-grouped.is-grouped-centered
                        button.button.is-danger(type="submit" :class="submitButton.classes") {{submitButton.text}}

                    p.has-text-centered.has-text-success.has-text-weight-bold(v-if="showSuccess") SUCCESS!
                .field.is-grouped.is-grouped-centered(v-else)
                    .control
                        .loader
                p.has-text-danger.has-text-centered.has-text-weight-bold {{err}}

</template>

<style scoped>
.container.growToPage {
    padding: 0.5rem;
}
.switchLabel {
    display: inline-block;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

</style>

<script>
import BSwitch from "buefy/src/components/switch/Switch";
export default {
    name: "Index",
    components: {BSwitch},
    data () {
        return {
            submitPending: false,
            charge: {
                vendor: null,
                item: null,
                amount: null,
                account: null,
                notes: null,
                charge_date: new Date(),
                have_receipt: true,
            },
            customTags: [],
            vendorTags: [],
            selectedTag: null,
            err: null,
            showSuccess: false,
            isExistingCharge: false,
            getExistingPending: false,
            updatePending: false,
            skippedInitialTagCheck: false,
            deletePending: false,
        }
    },
    activated: async function () {
        await this.recheckRoute();
    },
    watch: {
        'charge.vendor': function () {
            if (!this.isExistingCharge || this.skippedInitialTagCheck ) {
                this.vendorTags = this.$user.default_tags.filter((t) => t.vendor == this.charge.vendor && !this.customTags.includes(t.tag)).map(t => t.tag);
            } else if (this.isExistingCharge) {
                this.skippedInitialTagCheck = true;
            }
        },
        '$route.params.chargeID': function () {
            this.recheckRoute();
        }
    },
    methods: {
        recheckRoute: async function () {
            this.err = null;
            if (!isNaN(parseInt(this.$route.params.chargeID))) {
                this.isExistingCharge = true;
                this.skippedInitialTagCheck = false;
                this.getExistingCharge(this.$route.params.chargeID);
            } else {
                this.isExistingCharge = false;
                this.resetFields();
            }
        },
        getExistingCharge: async function (chargeID) {
            if (this.getExistingPending) return;
            this.getExistingPending = true;
            this.err = null;
            try {
                let r = await this.$api.charges.get(chargeID);
                this.$log.debug(`Got response for charge`);
                this.$log.debug(r);
                this.customTags = r.tags;
                for (let k of Object.keys(this.charge)) {
                    if (k == 'charge_date') {
                        this.charge.charge_date = new Date(r.info.charge_date);
                    } else {
                        this.charge[k] = r.info[k];
                    }
                }
                this.getExistingPending = false;
            } catch (e) {
                this.$log.debug("Failed to get existing charge");
                this.err = this.logError(e);
                this.getExistingPending = false;
            }
        },
        formatDate: function (date) {
            let d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        },
        updateExistingCharge: async function () {
            if (this.updatePending) return;

            this.updatePending = true;
            this.err = null;
            try {
                let infoCopy = JSON.parse(JSON.stringify(this.charge));
                infoCopy.charge_date = this.formatDate(infoCopy.charge_date);

                let r = await this.$api.charges.update(this.$route.params.chargeID, infoCopy, this.vendorTags.concat(this.customTags));
                this.triggerSuccess();
                // Reset all the fields
                this.resetFields();
                // Redirect to the report page
                this.$router.push('/reports/charges');
                this.updatePending = false;
            } catch (e) {
                this.$log.debug("Failed to update charge");
                this.err = this.logError(e);
                this.updatePending = false;
            }
        },
        createCharge: async function () {
            if (this.submitPending) return;

            this.submitPending = true;
            this.err = null;
            try {
                let infoCopy = JSON.parse(JSON.stringify(this.charge));
                infoCopy.charge_date = this.formatDate(infoCopy.charge_date);

                let r = await this.$api.charges.create(infoCopy, this.vendorTags.concat(this.customTags));
                this.triggerSuccess();
                // Reset all the fields
                this.resetFields();
                this.submitPending = false;
            } catch (e) {
                this.$log.debug("Failed to create new charge");
                this.err = this.logError(e);
                this.submitPending = false;
            }
        },
        addCustomTag: function () {
            let t = parseInt(this.selectedTag);
            if (!this.fullTagList.includes(t)) {
                this.$log.debug("add to list " + t);
                console.log(this.fullTagList);
                this.customTags.push(t);
            }
        },
        deleteTag: function (t) {
            this.customTags = this.customTags.filter(x => x != t);
            this.vendorTags = this.vendorTags.filter(x => x != t);
        },
        triggerSuccess: async function () {
            this.showSuccess = true;
            await this.waitSecondsAsync(2);
            this.showSuccess = false;
        },
        pushCharge: async function () {
            if (this.isExistingCharge) {
                this.updateExistingCharge();
            } else {
                this.createCharge();
            }
        },
        deleteCharge: async function () {
            if (this.deletePending) return;

            this.deletePending = true;
            this.err = null;
            try {
                await this.$api.charges.delete(this.$route.params.chargeID);
                // Reset all the fields
                this.resetFields();
                // Redirect to the report page
                this.$router.push('/reports/charges');
                this.deletePending = false;
            } catch (e) {
                this.$log.debug("Failed to delete charge");
                this.err = this.logError(e);
                this.deletePending = false;
            }
        },
        resetFields: function () {
            this.charge = {
                vendor: null,
                item: null,
                amount: null,
                account: null,
                notes: null,
                charge_date: new Date(),
                have_receipt: true,
            };
            this.customTags = [];
            this.vendorTags = [];
            this.selectedTag = null;
        }
    },
    computed: {
        fullTagList: function () {
            return this.customTags.concat(this.vendorTags);
        },
        submitButton: function () {
            return {
                classes: {
                    'is-loading': this.submitPending || this.updatePending
                },
                text: this.isExistingCharge ? 'Update' : 'Submit'
            }

        },
        deleteButton: function () {
            return {
                classes: {
                    'is-loading': this.deletePending
                }
            }
        }
    }
}
</script>
