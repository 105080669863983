<template lang="pug">
.container.growToPage
    .title.is-4.has-text-centered Charge Report

    .level
        .level-left
            .level-item
                label.label.alignWithInput Date Range:
            .level-item
                b-datepicker.smallPicker(v-model="filters.startDate")
            .level-item
                span -
            .level-item
                b-datepicker.smallPicker(v-model="filters.endDate")

            .level-item.margin-left
                label.label Sort By:
            .level-item
                .select
                    select(v-model="filters.sortby")
                        option(value='charge_date') Date
                        option(value='id') ID
                        option(value='amount') Amount
            .level-item
                span.sortOrderIcon.alignWithInput.fas(:class="sortOrderIcon.classes" @click="swapSortOrder")

            .level-item.margin-left
                label.label Has Tag:
            .level-item
                .select.smallPicker
                    select(v-model="filters.hasTag")
                        option(value='any') Any
                        option(value='none') None
                        option(v-for="c in Object.keys($user.tags)" :value="c") {{$user.tags[c].name}}

        .level-right
            .level-item
                button.button.is-danger(@click="downloadCSV") CSV EXPORT

    section.hero.is-dark(@click="toggleExtras")
        .hero-body.tinyHero
            .level.is-mobile
                .level-left
                    p.is-size-5 TOTALS
                .level-right
                    .level-item
                        span.is-size-4.fal(:class="extrasDropdown.classes")
    .remainder(v-if="extrasVisible")
        .columns
            .column.is-6
                .box
                    p.has-text-weight-bold {{`TOTAL SPEND: ${totalsData.total.toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}`}}
                .box
                    table.table
                        thead
                            tr
                                th Account
                                th Spent
                        tbody
                            tr(v-for="c in Object.keys(totalsData.accounts)")
                                td {{$user.accounts[c].name}}
                                td {{totalsData.accounts[c].toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}

            .column.is-6
                .box
                    table.table
                        thead
                            tr
                                th Tag
                                th Spent
                        tbody
                            tr(v-for="c in Object.keys(totalsData.tags)")
                                td {{$user.tags[c].name}}
                                td {{totalsData.tags[c].toLocaleString('en-JP', { style: 'currency', currency: 'JPY' })}}


    .chargeTable
        table.table.is-fullwidth.is-striped.is-hoverable.is-narrow(v-if="!loading")
            thead
                tr
                    th Date
                    th ID
                    th Item
                    th Amount
                    th Account
                    th Vendor
                    th Tags
                    th Receipt
                    th Notes
            tbody
                router-link(v-for="c in filteredItems" tag="tr" :to="'/charge/' + c.id")
                    td.nowrap {{c.charge_date}}
                    td {{c.id}}
                    td {{c.item}}
                    td ¥{{c.amount}}
                    td {{$user.accounts[c.account].name}}
                    td {{$user.vendors[c.vendor].name}}
                    td {{tagCSV(c.tags)}}
                    td {{c.have_receipt == true ? 'Yes' : 'No'}}
                    td {{c.notes}}

        .field.is-grouped.is-grouped-centered(v-else)
            .control
                .loader



    p.has-text-weight-bold.has-text-danger.has-text-centered {{err}}
</template>

<style scoped>
.container.growToPage {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.chargeTable {
    margin-top: 1rem;
    max-width: 100vw;
    overflow: auto;
}
.nowrap {
    white-space: nowrap;
}
.sortOrderIcon {
    font-size: 24px;
    cursor: pointer;
}
.margin-left {
    margin-left: 1rem;
}
.smallPicker {
    max-width: 110px;
}
.tinyHero {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}
.remainder {
    border-left: 1px solid #292929;
    border-right: 1px solid #292929;
    border-bottom: 1px solid #292929;
    padding: 0.5rem;
}
</style>

<script>
export default {
    name: "ChargeReport",
    data () {
        return {
            loading: true,
            charges: [],
            filters: {
                sortby: 'charge_date',
                sortorder: true,
                startDate: new Date(this.$user.startDate),
                endDate: new Date(this.$user.endDate),
                hasTag: 'any',
            },
            extrasVisible: false,
            err: null,
        }
    },
    activated: async function () {
        await this.getCharges();
    },
    methods: {
        getCharges: async function () {
            this.loading = true;

            try {
                this.charges = await this.$api.charges.get_all({
                    dt: '2021-06-01'
                });
                this.$log.debug(this.charges);
                this.loading = false;
            } catch (e) {
                this.$log.debug("Failed to get all charges");
                this.err = this.logError(e);
            }
        },
        tagCSV: function (tagList) {
            let s = '';
            for (let t of tagList) {
                s += `${this.$user.tags[t].name}, `;
            }
            return s.slice(0, s.length-2);
        },
        swapSortOrder: function () {
            this.filters.sortorder = !this.filters.sortorder;
        },
        downloadCSV: function () {
            let csvContent = "data:text/csv;charset=utf-8,";

            if (this.filteredItems.length == 0) {
                this.$log.error("NO ITEMS TO EXPORT - ADJUST FILTERS");
                return;
            }

            const exportFields = ['id', 'charge_date', 'amount', 'item', 'have_receipt'];
            csvContent += exportFields.join(',') + ",";
            csvContent += ['vendor', 'account'].join(',');
            csvContent += "\r\n";

            for (let r of this.filteredItems) {
                csvContent += exportFields.map(a => String(r[a]).replace(/(\r\n|\n|\r|,)/gm, " ")).join(',') + ",";
                // Need to add the actual strings for vendor and account
                csvContent += [this.$user.vendors[r.vendor].name, this.$user.accounts[r.account].name].join(',') + "\r\n";
            }

            let encodedURI = encodeURI(csvContent);

            let link = document.createElement('a');
            link.setAttribute('href', encodedURI);
            link.setAttribute('download', 'charge_report.csv');
            document.body.appendChild(link);
            link.click();
        },
        toggleExtras: function () {
            this.extrasVisible = !this.extrasVisible;
        },
    },
    computed: {
        filteredItems: function () {
            return this.charges.filter((a) => {
                let ok = true;

                let d = new Date(a.charge_date);
                if (d.getTime() < this.filters.startDate.getTime() || d.getTime() > this.filters.endDate.getTime()) {
                    return false;
                }

                if (this.filters.hasTag != 'any') {
                    if (this.filters.hasTag == 'none') {
                        if (a.tags.length != 0) {
                            return false;
                        }
                    } else if (!a.tags.includes(parseInt(this.filters.hasTag))) {
                        return false;
                    }
                }

                return ok;
            }).sort((a, b) => {
                let c1 = a[this.filters.sortby];
                let c2 = b[this.filters.sortby];

                if (this.filters.sortby == 'charge_date') {
                    c1 = (new Date(a.charge_date)).getTime();
                    c2 = (new Date(b.charge_date)).getTime();
                }

                if (c1 > c2) {
                    return this.filters.sortorder ? -1 : 1;
                } else if (c2 > c1) {
                    return this.filters.sortorder ? 1 : -1;
                } else {
                    if (a.id > b.id) return this.filters.sortorder ? 1 : -1;
                    return 0;
                }
            });
        },
        sortOrderIcon: function () {
            return {
                classes: {
                    'fa-angle-double-up': this.filters.sortorder,
                    'has-text-success': this.filters.sortorder,
                    'fa-angle-double-down': !this.filters.sortorder,
                    'has-text-danger': !this.filters.sortorder,
                }
            }
        },
        extrasDropdown: function () {
            return {
                classes: {
                    'fa-angle-double-down': !this.extrasVisible,
                    'fa-angle-double-up': this.extrasVisible,
                }
            }
        },
        totalsData: function () {

            let accounts = {};
            let tags = {};
            let total = 0;

            for (let b of this.filteredItems) {
                if (accounts[b.account] != null) {
                    accounts[b.account] += parseInt(b.amount);
                } else {
                    accounts[b.account] = parseInt(b.amount);
                }

                for (let t of b.tags) {
                    if (tags[t] != null) {
                        tags[t] += parseInt(b.amount);
                    } else {
                        tags[t] = parseInt(b.amount);
                    }
                }

                total += parseInt(b.amount);
            }

            return {
                accounts,
                total,
                tags,
            }
        },
    }
}
</script>

