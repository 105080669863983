class UserStore {
    constructor(Vue, options) {
        // Check for existing token in localstorage
        let localToken = localStorage.getItem('user-jwt') || '';

        // Lets make a new instance to store the data
        this.storeVM = new Vue({
            data() {
                return {
                    token: localToken === '' ? null : localToken,
                    vendors: {},
                    tags: {},
                    accounts: {},
                    default_tags: [],
                    initial_load_complete: false,
                };
            },
        });
    }

    get is_authenticated() {
        return this.storeVM.$data.token != null;
    }

    get info() {
        if (this.storeVM.$data.token != null) {
            return JSON.parse(atob(this.storeVM.$data.token.split('.')[1]));
        }
        return null;
    }

    get startDate() {
        // Change in July
        const timeNow = new Date();
        if (timeNow.getMonth() >= 6) {
            return `${timeNow.getFullYear()}-06-01`;
        } else {
            return `${timeNow.getFullYear()-1}-06-01`;
        }
    }

    get endDate() {
        // Change in July
        const timeNow = new Date();
        if (timeNow.getMonth() >= 6) {
            return `${timeNow.getFullYear()+1}-05-31`;
        } else {
            return `${timeNow.getFullYear()}-05-31`;
        }
    }

    get token() {
        return this.storeVM.$data.token;
    }

    set token(t) {
        this.storeVM.$data.token = t;
    }

    get vendors() {
        return this.storeVM.$data.vendors;
    }

    get tags() {
        return this.storeVM.$data.tags;
    }

    get accounts() {
        return this.storeVM.$data.accounts;
    }

    get default_tags() {
        return this.storeVM.$data.default_tags;
    }

    get sorted_tag_ids() {
        return Object.keys(this.storeVM.$data.tags).sort((a, b) => {
            if (this.storeVM.$data.tags[a].name > this.storeVM.$data.tags[b].name) return 1;
            return -1;
        });
    }

    get sorted_vendor_ids() {
        return Object.keys(this.storeVM.$data.vendors).sort((a, b) => {
            if (this.storeVM.$data.vendors[a].name > this.storeVM.$data.vendors[b].name) return 1;
            return -1;
        });
    }

    get sorted_account_ids() {
        return Object.keys(this.storeVM.$data.accounts).sort((a, b) => {
            if (this.storeVM.$data.accounts[a].name > this.storeVM.$data.accounts[b].name) return 1;
            return -1;
        });
    }

    delete_default_tag(t) {
        this.storeVM.$data.default_tags = this.storeVM.$data.default_tags.filter(x => !(x.tag == t.tag && x.vendor == t.vendor));
    }


    get initial_load_complete() {
        return this.storeVM.$data.initial_load_complete;
    }

    set initial_load_complete(b) {
        this.storeVM.$data.initial_load_complete = b;
    }

    add_vendor(nv) {
        this.storeVM.$set(this.storeVM.$data.vendors, nv.id, nv);
    }

    add_tag(n) {
        this.storeVM.$set(this.storeVM.$data.tags, n.id, n);
    }

    add_account(n) {
        this.storeVM.$set(this.storeVM.$data.accounts, n.id, n);
    }

    add_default_tag(n) {
        if (!this.storeVM.$data.default_tags.includes(n)) {
            this.storeVM.$data.default_tags.push(n);
        }
    }

    empty_store() {
        this.storeVM.$data.initial_load_complete = false;
        this.storeVM.$data.vendors = {};
        this.storeVM.$data.tags = {};
        this.storeVM.$data.accounts = {};
        this.storeVM.$data.default_tags = [];
    }

}


export default {
    /**
     * Install plugin
     * @param {Vue} Vue - Vue instance
     * @param {Object} options - Options for the plugin
     */
    install: (Vue, options = {}) => {
        let d = new UserStore(Vue, options);
        Vue.user = d;
        Vue.prototype.$user = d;
    },
};

// Usage: import UserStore from 'plugins/store'; Vue.use(UserStore);
