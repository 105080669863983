<template lang="pug">
.container
    .columns(v-if="storeLoaded")
        .column.is-3
            nav.panel
                p.panel-heading Account List
                a.panel-block(v-for="v in sortedItemIds" :class="isActive(v)" @click="swapActive(v)")
                    span {{itemList[v].name || `New Account #${v}`}}
                a.panel-block
                    button.button.is-primary.is-fullwidth(@click="createNewAccount" :class="{'is-loading': createPending}") Create New Account

        .column.is-9
            .box(v-if="activeItem.id != null")
                .level
                    .level-left
                        .level-item
                            .temp
                                h4.title.is-4 {{activeItem.name || `New Account`}}
                                p.has-text-weight-bold {{`ID: #${activeItem.id}`}}
                    .level-right
                        .level-item
                            button.button.is-small(:class="editButton.classes" @click="toggleEditing") {{editButton.text}}
                hr
                .field
                    label.label Name
                    .control
                        input.input(:readonly="!editing" type="text" v-model="activeItemCopy.name" :class="formEditableClasses" placeholder="Not set")
                .field
                    label.label Starting balance (¥)
                    .control
                        input.input(:readonly="!editing" type="number" min="0" v-model="activeItemCopy.starting_balance" :class="formEditableClasses" placeholder="Not set")
                .field
                    label.label Notes
                    .control
                        textarea.textarea(:readonly="!editing" v-model="activeItemCopy.notes" :class="formEditableClasses" placeholder="Not set")

            p.has-text-danger.has-text-centered.has-text-weight-bold {{err}}

    .field.is-grouped.is-grouped-centered(v-else)
        .control
            .loader


</template>

<style scoped>
.underlinedFields {
    border-bottom: 1px solid #dbdbdb;
}

@media screen and (max-width: 769px) {
    .container {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

</style>

<script>
export default {
    name: "Accounts",
    data: function () {
        return {
            createPending: false,
            activeItemId: null,
            activeItemCopy: {},
            editing: false,
            editPending: false,
            err: null,
        }
    },
    mounted: function () {
        // If the store is already loaded we need to copy the item
        if (this.storeLoaded) {
            this.swapActive(this.activeItemId);
        }
    },
    watch: {
        storeLoaded: function (is, was) {
            this.swapActive(this.activeItemId);
        }
    },
    methods: {
        createNewAccount: async function () {
            if (this.createPending) return;

            this.createPending = true;
            this.err = null;
            try {
                let newID = await this.$api.accounts.create();
                // Make it the new default
                this.swapActive(newID);

                this.createPending = false;
            } catch (e) {
                this.$log.debug('Failed to create new account');
                this.err = this.logError(e);
                this.createPending = false;
            }
        },
        isActive: function (i) {
            return {
                'is-active': i == this.activeItemId
            }
        },
        swapActive: function (i) {
            if (this.editPending) return;

            if (Object.keys(this.itemList).length > 0) {
                this.editing = false;

                if (i == null) {
                    this.activeItemId = this.sortedItemIds[0];
                } else {
                    this.activeItemId = i;
                }
                this.activeItemCopy = JSON.parse(JSON.stringify(this.itemList[this.activeItemId]));
            }
        },
        toggleEditing: async function () {
            if (this.editPending) return;

            this.editing = !this.editing;
            if (!this.editing) {
                this.err = null;
                this.editPending = true;
                try {
                    await this.$api.accounts.update(this.activeItemId, this.activeItemCopy);
                    this.editPending = false;
                } catch (e) {
                    this.$log.debug('Failed to update account');
                    this.err = this.logError(e);
                    this.editPending = false;
                }
            }
        }
    },
    computed: {
        sortedItemIds: function () {
            return Object.keys(this.itemList).sort((a, b) => {
                if (this.itemList[a].name > this.itemList[b].name) return 1;
                return -1;
            });
        },
        itemList: function () {
            return this.$user.accounts;
        },
        activeItem: function () {
            return this.itemList[this.activeItemId] || {};
        },
        formEditableClasses: function () {
            return {
                'is-static': !this.editing,
                'underlinedFields': !this.editing
            }
        },
        editButton: function () {
            return {
                classes: {
                    'is-info': (!this.editing && !this.editPending),
                    'is-danger': (this.editing || this.editPending),
                    'is-loading': this.editPending
                },
                text: this.editing ? 'SAVE' : 'EDIT'
            }
        },
        storeLoaded: function () {
            return this.$user.initial_load_complete;
        }
    }
}
</script>

